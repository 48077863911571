<template>
  <div>
    <!-- <v-container fluid fill-height class="content">
      <v-row align="center" justify="center"
        >
      <v-col align="center" cols="12" md="6" v-if="!isEmailVerifying">
        <v-card
          max-width="500"
          class="rounded-card"
        >
           <v-card-title class="justify-center pa-4">
                      <h2>Create An Account</h2>
                      </v-card-title>
                      <v-form ref="Formref">
                    <v-row class="mx-5 pt-12">
                      <v-col
                            cols="12"
                            class="pa-0 px-4"
                            >
                            <v-text-field
                            color="teal darken-2"
                                class="font-size-14"
                                v-model="firstname"
                                 :rules="[formRules.required]" 
                                label="Firstname"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0 px-4"
                            >
                            <v-text-field
                            color="teal darken-2"
                                class="font-size-14"
                                v-model="middlename"
                                 :rules="[formRules.required]" 
                                label="Middlename"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0 px-4"
                            >
                            <v-text-field
                            color="teal darken-2"
                                class="font-size-14"
                                v-model="surname"
                                 :rules="[formRules.required]" 
                                label="Surname"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0 px-4 mt-2"
                            >
                            <v-text-field
                              color="teal darken-2"
                                class="font-size-14"
                                v-model="email"
                                :rules="[formRules.required, formRules.email]"
                                label="Email address"
                                required
                                dense
                                @change="checkEmail()"
                                :error-messages="emailError"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0 px-4 mt-2"
                            >
                            <v-text-field
                                color="teal darken-2"
                                class="font-size-14"
                                dense
                                v-model="password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[formRules.required, , formRules.password]"
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Password"
                                @click:append="show1 = !show1"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0 px-4 mt-2"
                            >
                            <v-text-field
                                color="teal darken-2"
                                class="font-size-14"
                                dense
                                v-model="confirmPassword"
                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[formRules.required, formRules.confirmPassword(confirmPassword, password)]"
                                :type="show2 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Confirm Password"
                                @click:append="show2 = !show2"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12">
                            <div class="d-flex justify-center">
                                <v-btn
                                  depressed
                                  color="teal darken-2"
                                  class="white--text py-2 font-size-14"
                                  :loading="isLoading"
                                  @click="register()">Register</v-btn>
                            </div>
                        </v-col>
                        <v-col
                            cols="12">
                            <div class="d-flex justify-center pt-8 pb-3 font-size-14">
                                Already have an account?
                                <router-link class="pl-2"  to ="/login">
                                  Login</router-link>
                            </div>
                        </v-col>
                    </v-row>
            </v-form>
        </v-card>
      </v-col>
      <v-col align="center" cols="12" md="6" v-if="isEmailVerifying">
        <v-card
          max-width="500"
          class="rounded-card"
        >
           <v-card-title class="justify-center pa-4">
                      <h2>Email Verification</h2><br /><br /><br />
                      </v-card-title>
                      <v-form ref="Formref">
                    <v-row class="mx-5">
                        <v-col
                            cols="12"
                            >
                          <div class="otp-div">
                          <v-otp-input
                              ref="otpInput"
                              input-classes="otp-input"
                              separator="-"
                              :num-inputs="6"
                              :should-auto-focus="true"
                              :is-input-num="true"
                              @on-complete="handleOnComplete"
                          />
                          </div>
                        </v-col>
                        <v-col
                            cols="12">
                            <div class="d-flex justify-center">
                                <v-btn
                                  depressed
                                  color="teal darken-2"
                                  class="white--text py-2 font-size-14"
                                  :loading="isLoading"
                                  :disabled="!enableOTPBtn"
                                  @click="confirmOtp()">Confirm</v-btn>
                            </div>
                        </v-col>
                        <v-col
                            cols="12">
                            <div class="d-flex justify-center pt-8 pb-3 font-size-14">
                                Already have an account?
                                <router-link class="pl-2"  to ="/login">
                                  Login</router-link>
                            </div>
                        </v-col>
                    </v-row>
            </v-form>
        </v-card>
      </v-col>
      </v-row>
  </v-container>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component> -->
  </div>
</template>

<script>
  export default {
    name: 'Register',
    mounted () {
      if(this.$route.name == 'OTP'){
        this.isEmailVerifying = true;
        this.email = this.$router.currentRoute.params.email;
      }
      if(this.$store.state.user){
      if(!this.$store.state.user.roleID){
        //this.deleteToken();
        setTimeout(function(){  location.reload(); }, 0);
      }
      }
    },
    methods: {
        confirmOtp () {
            this.isLoading = true;
             let data = new FormData();
                data.append('otp', this.otp);
                data.append('email', this.email);
                this.axiosCall(
                        '/validate',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                          this.fadeAwayMessage.show = true;
                          this.fadeAwayMessage.type = 'success';
                          this.fadeAwayMessage.message = res.data.message;
                          this.fadeAwayMessage.header = 'System Message';
                          this.$router.replace({path: '/login'});

                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = res.data.message;
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
        },
        checkEmail() {
                this.emailChecking = true;
                let data = new FormData();
                data.append('email', this.email);
                this.axiosCall(
                        '/auth/checkEmailExist',
                        'POST',
                        data
                    )
                    .then((res) => {
                        console.log(res.data)
                        if(res.data.status) {
                            this.emailError = '';
                        }else {
                            this.emailError = 'Email Already Exist!';
                        }
                        this.emailChecking = false
                    })
        },
        register(){
                if(this.$refs.Formref.validate()){
                this.isLoading = true;
                let data = new FormData();
                data.append('email', this.email);
                data.append('password', this.password);
                data.append('surname', this.surname);
                data.append('middlename', this.middlename);
                data.append('firstname', this.firstname);
                this.axiosCall(
                        '/auth/register',
                        'POST',
                        data
                    )
                    .then((res) => {
                        if (res.data.status){
                        this.isEmailVerifying = true;
                        this.isLoading = false;
                        }
                        else{
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = res.data.message;
                        this.fadeAwayMessage.header = 'System Message';
                        this.isEmailVerifying = false;
                        }
                    })
                 }
        },
        handleOnComplete(value) {
            //console.log('OTP completed: ', value);
            if(value.length<6){
              this.enableOTPBtn = false;
            }
            else{
              this.enableOTPBtn = true;
            }
            this.otp = value;
        },
        handleClearInput() {
            this.$refs.otpInput.clearInput();
            this.enableOTPBtn = false;
        },
    },
    data: () => ({
      isLoading: false,
      firstname: '',
      middlename: '',
      surname: '',
      email: '',
      password: '',
      confirmPassword: '',
      show1: false,
      show2: false,
      emailError: '',
      emailChecking: false,
      isEmailVerifying: false,
      otp: '',
      enableOTPBtn: false,
      fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    }),
  }
</script>

<style scoped>
.content {
    background: #00523F;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 100px;
    overflow-y: auto;
    font-family: arial;
}
.title {
  color: #fff;
  font-size: 35px !important;
  position: relative;
  font-weight: bold;
}
.title .underline {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.title .underline .u1 {
  background: #fff;
  width: 40%;
  height: 5px;
  margin: 0 5px;
}
.title .underline .u2 {
  background: #fff;
  width: 5%;
  height: 5px;
  margin: 0 5px;
}
.lp-img {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.09));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.09));
  border-radius: 50%;
  background: #fff;
}
.rounded-card {
  border-radius: 10px;
}
</style>
<style>
.otp-div {
  display: flex;
  justify-content: center;
}
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.otp-input.error {
	border: 1px solid red !important;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
