<template>
  <div>
    <Dashboard1 v-if="role == 'agent' || role == 'executive_supervisor'" />
    <Dashboard2 v-else-if="role == 'admin'" />
  </div>
</template>

<script>
  export default {
    components: {
      Dashboard1: () => import("./Dashboard1.vue"),
      Dashboard2: () => import("./Dashboard2.vue"),
    },
    data: () => ({
      role: null
    }),
    created(){
      this.role = this.$store.state.user.role
    }
    
  }
</script>