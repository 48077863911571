import Vue from 'vue';
const eventHub = new Vue();
import axios from 'axios';
import moment from 'moment';

export default {
    created: function() {
        this.token = localStorage.getItem('token');
    },
    data: function() {
        return {
            token: '',
            apiUrl: process.env.VUE_APP_SERVER,
            eventHub: eventHub,
            translate_code: null,
            formRules: {
                amountExceeded: function(amount, principal, num_of_months) {
                    if (parseFloat(amount) <= parseFloat(principal)) {
                        if(parseFloat(amount) < parseFloat(principal) && num_of_months > 1){
                            return 'Amount must be equal to the payment amount for multiple months';
                        }
                        else{
                            return true;
                        } 
                    }
                    else {
                        return 'Amount must be less than or equal to the payment amount';
                    }
                },
                numberRequired: function(value, customMessage) {
                    if (!(value <= 0)) {
                        return true;
                    } else {
                        return (customMessage) ? customMessage : 'Should be more than zero';
                    }
                },
                dateNotEqual: function(value1, value2) {
                    if (!value1 || !value2) {
                        return true;
                    } else {
                        return value1 !== value2 || 'Dates should not be equal.';
                    }
                },
                emailEqual: function(value1, value2) {
                    if (!value1 || !value2) {
                        return true;
                    } else {
                        return value1 == value2 || 'Emails inputted are not the same.';
                    }
                },
                noNegativeNumberAllowed: function(value, customMessage) {
                    if (!(value < 0) || value == null || value == "") {
                        return true;
                    } else {
                        return (customMessage) ? customMessage : 'Negative value not allowed';
                    }
                },
                numberRequiredButEmptyAllowed: function(value, customMessage) {
                    if (!(value <= 0) || value == null || value == "") {
                        return true;
                    } else {
                        return (customMessage) ? customMessage : 'Should be more than zero';
                    }
                },
                restrict: function(value, customMessage) {
                    if (!value) {
                        return true;
                    } else {
                        return (customMessage) ? customMessage : 'Invalid country!';
                    }
                },
                checkAnswer: function(choice, answer, followUp) {
                    // console.log(choice, answer, followUp)
                    if (answer == null) {
                        return true;
                    }
                    if (choice == answer) {
                        return true;
                    }
                    else if(choice != answer && followUp > 0 ){
                        return true
                    }
                    else {
                        return ' ';
                    }
                },
                passportExpiry: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    else{
                    const nextMonthPlus7Days = new Date();
                    const date = new Date(value);
                    nextMonthPlus7Days.setMonth(nextMonthPlus7Days.getMonth() + 1);
                    nextMonthPlus7Days.setDate(nextMonthPlus7Days.getDate() + 7);
                    return nextMonthPlus7Days.getTime() < date.getTime() || 'Passport expiry date must atleast 1 month & 7 days or more';
                    }
                },
                noDecimal: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^[1-9]\d{0,2}(\.\d{3})*(,\d+)?$/
                    return pattern.test(value) || 'Decimal is not allowed'
                },
                required: value => !!value || 'This field is required',
                required2: value => !!value || '',
                required3: value => typeof value == "boolean" || 'This field is required',
                certify: value => !!value || '',
                englishOnly: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^[a-zA-Z ]*$/
                    return pattern.test(value) || 'Only English aphabets are allowed.'
                },
                englishAndNumberOnly: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^[a-z0-9_.,'"!?;:& -]+$/i
                    return pattern.test(value) || 'Only English characters and numbers are allowed.'
                },
                email: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    // const pattern1 = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/
                    // const pattern = /^[a-z0-9](?!.*?[^\na-z0-9]{2}).*?[a-z0-9]$/gmi
                    const pattern1 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
                    const pattern = /^[a-z0-9](?!.*?[^\na-z0-9]{2}).*?[a-z0-9]$/gmi;
                    return (pattern.test(value) && pattern1.test(value)) || 'Invalid email.'
                },
                requiredArray: (arr) => {
                    return (Array.isArray(arr)) ? (arr.length > 0) ? true : 'Required' : 'Required';
                },
                zipcode: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^[0-9]{5}(?:-[0-9]{4})?$/
                    return pattern.test(value) || 'Invalid zipcode';
                },
                dashAndUnderscore: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^[a-zA-Z0-9-_]+$/
                    return pattern.test(value) || 'Undercore and Dash are only allowed';
                },
                alphaNumbericunderscoreDash: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^[a-zA-Z0-9-_]+$/
                    return pattern.test(value) || 'Alpha,Numberic,Undercore, & Dash are only allowed';
                },
                password: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
                    return pattern.test(value) || 'Password should be at least 8 characters, must have 1 special character, must have 1 small letter,1 capital letter, and 1 number';
                },

                confirmPassword: function(a, b) {
                    if (a === null || a == '' || b === null || b == '') {
                        return true;
                    }
                    return (a === b) || 'The passwords are not identical. Please try again'
                },

                isRequiredIfValueNotEmpty: function(value, thisValue) {
                    if (value === null || value == '') {
                        return true;
                    } else {
                        return !!thisValue || 'Required'
                    }
                },
            },
            userData: {},
        }
    },
    methods: {
        formatPrice: function(value) {
            const val = (value / 1).toFixed(2).replace(',', '.');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        validateToken(value) {
            let regex = /\d\d-[A-Za-z][A-Za-z]-\d\d\d\d\d\d\d\d/i;
            return regex.test(value);
        },
        deleteToken: function() {
            localStorage.removeItem('token');
        },
        logout: function() {
            this.deleteToken();
            this.$router.replace("/");
        },
        closeDialog: function(){
            this.eventHub.$emit('closeDialog', false);
        },
        axiosCall: function(url, method, data, setToken) {
            let options = {
                method: method,
                url: this.apiUrl + url,
                data: data,
                headers: {}
            };

            if (method.toLowerCase() === 'get' && data) {
                if (Object.keys(data).length > 0) {
                    options.params = data;
                }
            }

            if (setToken == undefined || setToken) {
                if (this.token) {
                    options.headers = {
                        'Authorization': `Bearer ${this.token}`
                    };
                }
            }


            return axios(options)
                .catch((error) => {
                    console.log(error.response);
                    if (error.response.status == 401) {
                        this.deleteToken();
                        this.$router.push('/');
                    } 
                    else {
                        return error.response;
                    }
                });
        },
        toTitleCase: function(str) {
            return str.replace(
                /\w\S*/g,
                function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
        toLowerCaseAndDashSpace: function(str) {
            return str.replace(/\s+/g, '-').toLowerCase();
        },
        numberWithCommas: function(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        numberOnly: function(str) {
            return str;
        },
        truncateText: function(text, length, clamp) {
            clamp = clamp || '...';
            var node = document.createElement('div');
            node.innerHTML = text;
            var content = node.textContent;
            return content.length > length ? content.slice(0, length) + clamp : content;
        },
        splitFirstnameLastname: function(fullname) {
            let tempString = fullname.split(' '),
                firstName = tempString[0],
                lastName = tempString[tempString.length - 1];

            return {
                firstName: firstName,
                lastName: lastName
            }
        },
        formatDate(date, format = 'MMMM DD, YYYY') {
            if (date) {
                return moment(date).format(format);
            }
        },
        formatDate3(date, format = 'YYYY-MM-DD') {
            if (date) {
                return moment(date).format(format);
            }
        },
        formatDateTime(date, format = 'MMM. DD, YYYY HH:mm') {
            if (date) {
                return moment(date).format(format);
            }
        },
        formatDate2(date, format = 'D MMM YYYY') {
            if (date) {
                return moment(date).format(format);
            }
        },
        formatDateTime2(date, format = 'YYYY-MM-DD HH:mm:ss') {
            if (date) {
                return moment(date).format(format);
            }
        },
        formatDateTime3(date, format = 'dddd, MMMM D YYYY h:mm A') {
            if (date) {
                return moment(date).format(format);
            }
        },
        getImageFileForPreview: function(file, callback) {
            let reader = new FileReader();

            reader.onload = function(e) {
                callback(e.target.result);
            };

            reader.readAsDataURL(file);
        },
        createObjectReplicaNonReactive: function(data) {
            return JSON.parse(JSON.stringify(data));
        },

        decodeHtml: function(html) {
            var txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;
        },

        generateRandomCharacters: function(num = 20) {
            let text = "",
                chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (let i = 1; i <= num; i++) {
                text += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return text;
        },
        sanitizeString(str){
            if(str){
                return str.replaceAll(/[&/\\#,+()$~%.^'":*?<>{}]/g, "")
            }
            return str;
        },
        monthNumToName(num){
            let monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
              ];
            return monthNames[num - 1]
        },
        urlDecode(str){
            try {
                if (typeof str != "string") {
                    return str;
                }
                return decodeURIComponent(decodeURIComponent(decodeURIComponent(str)));
            } catch (error) {
                console.log(error)
            }
            
        },
        showToast(msg, type){
            let options = {
                position: "bottom-left",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
                }
            if(type == 'success'){
                this.$toast.success(msg, options);
            }
            else if(type == 'info'){
                this.$toast.info(msg, options);
            }
            else if(type == 'warning'){
                this.$toast.warning(msg, options);
            }
            else if(type == 'error'){
                this.$toast.error(msg, options);
            }
            else{
                this.$toast(msg, options);
            }
        },
        showToast2(msg, type){
            let options = {
                position: "top-center",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
                }
            if(type == 'success'){
                this.$toast.success(msg, options);
            }
            else if(type == 'info'){
                this.$toast.info(msg, options);
            }
            else if(type == 'warning'){
                this.$toast.warning(msg, options);
            }
            else if(type == 'error'){
                this.$toast.error(msg, options);
            }
            else{
                this.$toast(msg, options);
            }
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        strimwidth(str, start, width, ending) {
            if (width <= 0) return '';

            if (str.length <= start) return '';

            let sliced = str.slice(start, start + width);

            if (str.length <= width) return sliced;

            return sliced + ending;
        },
        async checkUrl(url) {
            try {
                const response = await fetch(url, {
                    method: 'HEAD',
                });
                return response.status;
            } catch (error) {
                console.error('Error fetching URL:', error);
                return null;
            }
        },
        cleanUAG(uag) {
            if(uag){
            return uag.replace(/[\r\n]/g, '');
            }
            else{
                return '';
            }
          },
          htmlentities(str) {
            const map = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#039;'
            };
            return str.replace(/[&<>"']/g, (match) => map[match]);
        },
        nl2br(str) {
            return str.replace(/\n/g, '<br>');
        },
        convertHtmlEntitiesAndNewlines(proof_html) {
            const escapedHtml = this.htmlentities(proof_html);
            return this.nl2br(escapedHtml);
        },
        ucwords(str) {
            return str.replace(/\b\w/g, (match) => match.toUpperCase());
        },
        toLowerCaseAndUcwords(str) {
            return this.ucwords(str.toLowerCase());
        },
        formatMoney(amount){
            amount = parseFloat(amount || 0);
            let formattedAmount = amount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true
            });
        
            return formattedAmount;
        },
        getStatsComparedChange(currentAmount, comparedAmount){
            let result = 0;
            if(currentAmount && comparedAmount){
                if(currentAmount == 0 && comparedAmount == 0){
                    result = "0.00";
                }
                else{
                    result = ((parseFloat(parseInt(currentAmount) - parseInt(comparedAmount)) / parseInt(comparedAmount)) * 100).toFixed(2);
                }
            }else{
                result = "0.00";
            }
            return result;
        },
        getStatsComparedTrend(changeAmount){
            if(parseFloat(changeAmount) <= 0) {
                return 'red';
            } else if(parseFloat(changeAmount) > 0){
                return 'green';
            } else {
                return 'grey';
            }
        },
        sanitizeJsonString(jsonString) {
            // Create a new string by filtering out unwanted characters
            let sanitized = '';
            
            for (let i = 0; i < jsonString.length; i++) {
                const char = jsonString[i];
                const code = char.charCodeAt(0);
                
                // Allow printable ASCII characters and necessary whitespace characters
                if (
                    (code >= 32 && code <= 126) || // Printable ASCII
                    char === '\n' ||                // Newline
                    char === '\r' ||                // Carriage return
                    char === '\t'                   // Tab
                ) {
                    sanitized += char;
                }
            }
        
            // Escape newlines, carriage returns, and tabs for valid JSON
            return sanitized
                .replace(/\n/g, '\\n')
                .replace(/\r/g, '\\r')
                .replace(/\t/g, '\\t');
        }   
    }

};