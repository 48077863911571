<template>
<div>
    <router-view v-if="render" />
    <loading-screen v-else />
</div>
</template>

<script>
export default {
  name: 'App',
  components: {
    "loading-screen": () => import("./components/Utils/LoadingScreen.vue"),
  },
  data: () => ({
    render: false,
  }),
  mounted() {
    this.render = false;
    this.getUser();
  },
  methods: {
    getUser() {
      if (localStorage.getItem("token")) {
        let token = {token: localStorage.getItem("token")}
        this.axiosCall("/auth/current_user", "POST", token)
          .then((res) => {
            if (res.data) {
              let data = res.data.user;
              this.$store.dispatch("setUser", data);
              if(this.$route.path == "/")
              this.$router.replace("/" + data.role + "/dashboard", () => {});
            }
          })
          .catch((error) => {
            console.log(error);
            this.render = true;
          });
      } else {
        this.render = true;
      }
    this.render = true;
    },

  },
};
</script>