<template>
  <div class="pa-5">
    <v-card class="card-style rounded-1" >
    <v-row class="ma-2">
      <v-col cols="12" md="9" class="flex-items">
        <h2>All Decisions</h2>
      </v-col>
      <v-col cols="12" md="3">
      <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          label="Search applications"
          single-line
          hide-details
          color="#38435a"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card class="mb-1 dt-container" elevation="0" outlined>
    <v-data-table
      :loading="loading"
       :headers="headers"
        :items="data"
        item-key="id"
        :options.sync="options"
        hide-default-footer
    >
    <template v-slot:[`item.submittedAtTimestamp`]="{ item }">
      <span v-if="item.submittedAtTimestamp" class="text-no-wrap">{{ formatDateTime2(item.submittedAtTimestamp) }}</span>
    </template>
    <template v-slot:[`item.country`]="{ item }">
      <span v-if="item.type == 'K-ETA' || item.type == 'UK-EVW'" class="flex-items">
        <img v-if="item.data.nationality" class="rounded-circle mr-2" width="18" :src="require('../../assets/img/flags/1x1/' + (item.data.nationality.toLowerCase()).substring(0, 2) + '.svg')" />
        {{getCountry(item.data.nationality)}}
      </span>
      <span v-else class="flex-items">
        <img v-if="item.data.country_of_citizenship" class="rounded-circle mr-2" width="18" :src="require('../../assets/img/flags/1x1/' + (item.data.country_of_citizenship.toLowerCase()).substring(0, 2) + '.svg')" />
        {{getCountry(item.data.country_of_citizenship)}}
      </span>
        
    </template>
    <template v-slot:[`item.applicationNumber`]="{ item }">
      <span v-if="item.applicationNumber">{{ strimwidth(item.applicationNumber, 0, 24, "...") }}</span>
    </template>
    <template v-slot:[`item.passportNumber`]="{ item }">
      <span v-if="item.data.passport_number">
        <span v-if="item.decisionSent == 0 && item.decisionAtTimestamp != null">{{ item.data.passport_number }}</span>
        <span v-else-if="item.decisionSent == 0 && item.decisionAtTimestamp == null">
         <span v-if="$store.state.user.role != 'agent'">
           {{ item.data.passport_number }}
         </span>
        </span>
        <span v-if="$store.state.user.role != 'admin'">
           {{ item.data.passport_number }}
         </span>
      </span>
    </template>
    <template v-slot:[`item.dob`]="{ item }">
        <span v-if="item.decisionSent == 0 && item.decisionAtTimestamp != null" class="text-no-wrap">{{ item.data.dob_day + ' / ' +  monthNumToName(item.data.dob_month) + ' / ' + item.data.dob_year}}</span>
        <span v-else-if="item.decisionSent == 0 && item.decisionAtTimestamp == null" class="text-no-wrap">
         <span v-if="$store.state.user.role != 'agent'">
           {{ item.data.dob_day + ' / ' +  monthNumToName(item.data.dob_month) + ' / ' + item.data.dob_year}}
         </span>
        </span>
        <span v-if="$store.state.user.role != 'admin'" class="text-no-wrap">
           {{ item.data.dob_day + ' / ' +  monthNumToName(item.data.dob_month) + ' / ' + item.data.dob_year}}
         </span>
    </template>
    <template v-slot:[`item.decision`]="{ item }">
        <v-chip
          class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
          v-if="item.decisionResult == 'approved'"
          small
        >
        <span>Approved</span>
        </v-chip>
        <v-chip
          class="chip-status-4 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
          v-if="item.decisionResult == null"
          small
        >
        <span>TBA</span>
        </v-chip>
        <v-chip
          class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
          v-else
          small
        >
        <span>Rejected</span>
        </v-chip>
    </template>
    <template v-slot:[`item.sent`]="{ item }">
        <v-chip
          class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
          v-if="item.decisionSent == 1"
          small
        >
        <v-icon small color="#2f8547">mdi-check</v-icon>
        </v-chip>
        <v-chip
          class="chip-status-5 rounded px-2 py-1 mr-2 my-1 font-weight-bold text-wrap"
          v-else
          small
        >
        <v-icon small color="#c4093c">mdi-close</v-icon>
        </v-chip>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      
      <span v-if="item.decisionSent == 0 && item.decisionAtTimestamp != null">
        <v-btn 
          outlined
          color="#166ecf"
          class="mr-2"
          @click="markDecision(item.token, 'approved')"
          small
        >
        <v-icon class="mr-1">mdi-check</v-icon>
        Approved
        </v-btn>
        <v-btn 
          outlined
          color="#b02c00"
          class="mr-2"
          @click="markDecision(item.token, 'rejected')"
          small
        >
        <v-icon class="mr-1">mdi-close</v-icon>
        </v-btn>
        <span v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'">
          <v-btn 
            outlined
            color="#c4093c"
            class="mr-2"
            @click="markDecision(item.token, 'expired')"
            small
          >
          <v-icon class="mr-1">mdi-alert-remove</v-icon>
          </v-btn>
        </span>
      </span>
      <span v-else-if="item.decisionSent == 0 && item.decisionAtTimestamp == null">
        <span v-if="item.type == 'UK-EVW' && item.data && item.data.passport_reupload_url">
          <span class="clipboard-style" v-clipboard="item.data.passport_reupload_url">Copy Passport URL</span>
        </span>
      </span>
      <span v-else>
        <v-btn
          v-if="$store.state.user.role == 'admin'"
          outlined
          small
          class="mr-2"
          color="#166ecf"
          :to="'/' + $store.state.user.role + '/search/view/'+ item.token + '/'"
        >
        <v-icon class="mr-1">mdi-eye</v-icon>
        View
        </v-btn>
      </span>   
    </template>
    </v-data-table>
    </v-card>
    <v-row class="ma-2" align="center" justify="center">
    <v-col cols="auto" class="flex-items">
        <span class="px-2">Row Per Page: </span>
        <span>
          <v-select
            dense
            outlined
            hide-details
            :value="options.itemsPerPage"
            style="max-width: 90px"
            class="rounded-lg"
            @change="options.itemsPerPage = parseInt($event, 10)"
            :items="perPageChoices"
          >
          </v-select>
        </span>
    </v-col>
    <v-col cols="auto" class="mx-auto text-truncate" no-gutters>
    Showing {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + 1 : 0 }} to {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + data.length : 0 }} of {{ paginationData.count }} entries
    </v-col>
    <v-col cols="auto">
        <v-pagination
            v-model="options.page"
            class="rounded-lg"
            :total-visible="7"
            color="#38435a"
            :length="paginationData.lastPage">
        </v-pagination>
    </v-col>
      </v-row>
    </v-card>
</div>
</template>
<script>
  export default {
    components: {
    },
    data: () => ({
      search: '',
      headers: [
          { text: 'SUBMITTED AT', value: 'submittedAtTimestamp' },
          { text: 'TYPE', value: 'type'},
          { text: 'COUNTRY', value: 'country'},
          { text: 'APPLICATION NUMBER', value: 'applicationNumber'},
          { text: 'PASSPORT NUMBER', value: 'passportNumber'},
          { text: 'DATE OF BIRTH', value: 'dob' },
          { text: 'DECISION', value: 'decision', sortable: false,},
          { text: 'SENT?', value: 'sent', sortable: false, },
          { text: 'ACTIONS', value: 'actions', sortable: false, },
        ],
      data: [],
      perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
      options: {},
      paginationData: {},
      loading: false,
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      search: {
        handler () {
          setTimeout(() => {
            this.options.page = 1;
            this.initialize();
          }, 600);
        }
      },
    },
    mounted() {
    },
    methods: {
      initialize (){
        this.loading = true;
        let page = this.options.page;
        let itemPerPage = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
        let order = this.options.sortBy.length > 0 ? this.options.sortDesc[0] ? 'desc' : 'asc' : '';
        let search = this.search;
        this.axiosCall('/applications/getAllDecisions?page=' + page + '&itemPerPage=' + itemPerPage + '&sortBy=' + sortBy + '&order=' + order + '&searchKey=' + search, 'GET').then((res) => {
              this.loading = false
              res.data.data.forEach(item => {
                item.data = JSON.parse(this.sanitizeJsonString(item.data))
              });
              this.data = res.data.data
              console.log(this.data)
              // console.log(this.data)
              this.paginationData = res.data.pagination;
          })
      },
      getCountry(lang) {
          let res = 'None'
          if(lang){
          this.$store.state.countries.forEach(item => {
            if(item.code.toLowerCase() == lang.toLowerCase()){
              res = item.country
            }
          });
          }
          return res;
        },
      markDecision(token, decision){
        let data = {
          token: token,
          decision: decision
          };
        this.axiosCall("/applications/decision", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
        });
       }
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #2f8547 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.chip-status-3{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-4{
  background-color: #fcedb9 !important;
  color: #b02c00 !important;
}
.chip-status-5{
  background-color: #ffe7f2 !important;
  color: #c4093c !important;
}
.chip-status-6{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
</style>