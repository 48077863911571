<template>
    <div class="py-8">
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="text-subtitle1 view-main-title">Applicant Information</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select Your Nationality:</v-col> <v-col cols="12" sm="6" class="py-1 flex-items clipboard-style" v-clipboard="getCountry(data.nationality)"><img v-if="data.nationality" class="rounded-circle mr-2" width="18" :src="require('../../assets/img/flags/1x1/' + (data.nationality.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{getCountry(data.nationality)}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Passport Number</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.passport_number"><strong>{{data.passport_number}}</strong></v-col>
          <!-- <v-col cols="12" sm="6" class="text-sm-end view-title py-1">System Email</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.systemEmail"><strong>{{data.systemEmail}}</strong></v-col> -->
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select the sex as written on your passport bio page</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.sex == 'M' ? 'Male' : 'Female'"><strong>{{data.sex == "M" ? "Male" : "Female"}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Surname</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="sanitizeString(data.family_names)"><strong>{{sanitizeString(data.family_names)}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">First (Given) Name</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="sanitizeString(data.first_given_names)"><strong>{{sanitizeString(urlDecode(data.first_given_names))}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Date of Birth (Year/Month/Day)</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.dob_year + ' / ' + monthNumToName(data.dob_month) + ' / ' + data.dob_day"><strong>{{data.dob_year + ' / ' + monthNumToName(data.dob_month) + ' / ' + data.dob_day}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Passport Expiration Date (Year/Month/Day)</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.passport_expiration_year + ' / ' + monthNumToName(data.passport_expiration_month) + ' / ' + data.passport_expiration_day"><strong>{{data.passport_expiration_year + ' / ' + monthNumToName(data.passport_expiration_month) + ' / ' + data.passport_expiration_day}}</strong></v-col>
          <v-col cols="12" v-if="data.national_identification_number">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">National Identification Number</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="sanitizeString(data.national_identification_number)"><strong>{{sanitizeString(data.national_identification_number)}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1" >Please select if you are also a national or citizen of another country other than the nationality of your passport</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.has_other_citizenship_country_bool ? 'Yes' : 'No'"><strong>{{data.has_other_citizenship_country_bool ? 'Yes' : 'No'}}</strong></v-col>
          <v-col cols="12" v-if="data.has_other_citizenship_country_bool">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country of Citizenship / Nationality</v-col> <v-col cols="12" sm="6" class="py-1"><strong>{{getCountry(data.has_other_citizenship_country)}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Phone Country Code</v-col> <v-col cols="12" sm="6" class="py-1 flex-items clipboard-style" v-clipboard="getCountry(getPhoneCountryCode(data.phone_number))"><img v-if="data.phone_number" class="rounded-circle mr-2" width="18" :src="require('../../assets/img/flags/1x1/' + (getPhoneCountryCode(data.phone_number).toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{getCountry(getPhoneCountryCode(data.phone_number))}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Phone Number</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.phone_number"><strong>{{data.phone_number}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever visited Korea before?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.visited_korea_before ? 'Yes' : 'No'"><strong>{{data.visited_korea_before ? "Yes" : "No"}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select your purpose of entry.</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.purpose_of_entry" ><strong>{{data.purpose_of_entry}}</strong></v-col>
          <v-col cols="12" v-if="data.purpose_of_entry == 'Other'">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Other purpose of entry.</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="urlDecode(data.purpose_of_entry_other)"><strong>{{urlDecode(data.purpose_of_entry_other)}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-else-if="data.purpose_of_entry == 'Tour'">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1" v-if="data.purpose_of_entry == 'Tour'">Tour Type</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.purpose_of_entry_tour_type"><strong>{{data.purpose_of_entry_tour_type}}</strong></v-col>
            </v-row>
            <v-row v-if="data.purpose_of_entry_tour_type == 'Group'">  
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Travel Agency Phone Number</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.purpose_of_entry_travel_agency_phone_number"><strong>{{data.purpose_of_entry_travel_agency_phone_number}}</strong></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Travel Agency Name</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.purpose_of_entry_travel_agency_name)).toUpperCase"><strong v-if="data.purpose_of_entry_travel_agency_name">{{(urlDecode(data.purpose_of_entry_travel_agency_name)).toUpperCase()}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="urlDecode(data.address)"><strong>{{urlDecode(data.address)}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address Details</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="urlDecode(data.address_details)"><strong>{{urlDecode(data.address_details)}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Please enter your contact number in Korea</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="sanitizeString(data.contact_phone_number)"><strong>{{sanitizeString(data.contact_phone_number)}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select the category that best represents your current job</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="job_categories[data.job_category]"><strong>{{job_categories[data.job_category]}}</strong></v-col>
          <v-col cols="12" v-if="data.job_category == 'Etc'">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Fill your current job</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="urlDecode(data.job_category_other)"><strong>{{urlDecode(data.job_category_other)}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Are you currently suffering from any of the following diseases?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.diseases_bool ? 'Yes' : 'No'"><strong>{{data.diseases_bool ? "Yes" : "No"}}</strong></v-col>
          <v-col cols="12" v-if="data.diseases_bool">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Please explain the illness you are suffering from in detail</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="urlDecode(data.diseases_explanation)"><strong>{{urlDecode(data.diseases_explanation)}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever been arrested, charged, or convicted of a criminal offense in your country or abroad?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.arrested_bool ? 'Yes' : 'No'"><strong>{{data.arrested_bool ? "Yes" : "No"}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Applicant Photo
            <br />
            <v-chip
              class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
              v-if="data.photo_details_optimized"
            >
            <span>Compressed</span>
            </v-chip>
            <v-chip
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
              v-else
            >
            <span>Non-Compressed</span>
            </v-chip>
            </v-col> <v-col cols="12" sm="6" class="py-1">
            <div v-if="data.photo_details_optimized">
            <div><img class="image-fluid rounded-lg" height="300" :src="data.photo_details_optimized"/></div>
            <v-btn color="#f5ca99" class="px-15 py-4" depressed :href="data.photo_details_optimized" download><v-icon>mdi-download</v-icon> Download Photo</v-btn>
            </div>
            <div v-else>
            <div><img class="image-fluid rounded-lg" height="300" :src="'https://esta-apply.s3.amazonaws.com/' + data.photo_details"/></div>
            <v-btn color="#f5ca99" class="px-15 py-4" depressed :href="'https://esta-apply.s3.amazonaws.com/' + data.photo_details" download><v-icon>mdi-download</v-icon> Download Photo</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="data.high_risk_nationality">
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select country of birth</v-col> <v-col cols="12" sm="6" class="py-1 flex-items clipboard-style" v-clipboard="getCountry(data.hrn_country_of_birth)"><strong><img v-if="data.hrn_country_of_birth" class="rounded-circle mr-2" width="18" :src="require('../../assets/img/flags/1x1/' + (data.hrn_country_of_birth.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{getCountry(data.hrn_country_of_birth)}}</strong></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Enter place of birth as written on your passport bio page</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="sanitizeString(urlDecode(data.hrn_place_of_birth))"><strong>{{sanitizeString(urlDecode(data.hrn_place_of_birth))}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Planned duration from date</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.hrn_planned_duration_from_date" style="color:#677788">NOT PROVIDED (OPTIONAL)</span><span v-else>{{formatDate3(data.hrn_planned_duration_from_date)}}</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Planned duration to date</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.hrn_planned_duration_to_date" style="color:#677788">NOT PROVIDED (OPTIONAL)</span><span v-else>{{formatDate3(data.hrn_planned_duration_to_date)}}</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Do you have any acquaintances in Korea?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.hrn_has_acquaintances_bool ? 'YES' : 'NO'"><strong>{{data.hrn_has_acquaintances_bool ? "YES" : "NO"}}</strong></v-col>
          <v-col cols="12" v-if="data.hrn_has_acquaintances_bool">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Acquaintance\'s Name</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.hrn_acquaintance_name) || '').toUpperCase()"><strong>{{(urlDecode(data.hrn_acquaintance_name) || '').toUpperCase()}}</strong></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Acquaintance\'s Phone Number</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="urlDecode(data.hrn_acquaintance_phone_number)"><strong>{{urlDecode(data.hrn_acquaintance_phone_number)}}</strong></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Relation</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="acq_relations[data.hrn_acquaintance_relation]"><strong>{{acq_relations[data.hrn_acquaintance_relation]}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Do you have an accompanying person?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.hrn_has_accompanying_person_bool ? 'YES' : 'NO'"><strong>{{data.hrn_has_accompanying_person_bool ? "YES" : "NO"}}</strong></v-col>
          <v-col cols="12" v-if="data.hrn_has_accompanying_person_bool">
            <v-row v-for="(person, index) in data.hrn_accompanying_persons" :key="index">
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Accompanying Person #{{index + 1}} Details</v-col> <v-col cols="12" sm="6" class="py-1"><strong>
              <div class="py-1"><span class="view-title">Nationality: </span><span class="pa-1 clipboard-style" v-clipboard="getCountry(person.nationality)">{{getCountry(person.nationality)}}</span></div>
              <div class="py-1"><span class="view-title">Name: </span><span v-if="person.name" class="pa-1 clipboard-style" v-clipboard="(urlDecode(person.name)).toUpperCase()">{{(urlDecode(person.name)).toUpperCase()}}</span></div>
              <div class="py-1"><span class="view-title">Date of Birth: </span><span class="pa-1 clipboard-style" v-clipboard="person.dob_year + ' / ' + monthNumToName(person.dob_month) + ' / ' + person.dob_day">{{person.dob_year + ' / ' + monthNumToName(person.dob_month) + ' / ' + person.dob_day}}</span></div>
              <div class="py-1"><span class="view-title">Relation: </span><span class="pa-1 clipboard-style" v-clipboard="acc_array[person.relation]">{{acc_array[person.relation]}}</span></div>
              </strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever visited another country other than the Republic of Korea?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="visited_arr[data.hrn_has_visited_another_country]"><strong>{{visited_arr[data.hrn_has_visited_another_country]}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Are you currently employed?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.hrn_is_currently_employed_bool ? 'YES' : 'NO'"><strong>{{data.hrn_is_currently_employed_bool ? "YES" : "NO"}}</strong></v-col>
          <v-col cols="12" v-if="data.hrn_is_currently_employed_bool">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Enter the name of your employer or company</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="urlDecode(data.hrn_employer_name)"><strong>{{urlDecode(data.hrn_employer_name)}}</strong></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Enter the phone number of your employer or company</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="urlDecode(data.hrn_employer_phone_number)"><strong>{{urlDecode(data.hrn_employer_phone_number)}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select the area that includes your monthly income (in USD)</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="income_arr[data.hrn_monthly_income]"><strong>{{income_arr[data.hrn_monthly_income]}}</strong></v-col>
        </v-row>
        </div>
</template>

<script>
  export default {
    props: {
        data: {}
    },
    components: {
    },
    data: () => ({
      purposes_of_entry: {
        'Tour': 'Tour', 
        'Visit': 'Visit',
        'Business': 'Business',
        'Medical': 'Medical treatment',
        'Meeting': 'Meeting',
        'Event': 'Event',
        'Games': 'Games/Match/Competition',
        'Other': 'Other'
      },
      job_categories: {
        'Unemployed': 'Unemployed',
        'Selfemployed': 'Self-employed',
        'Agricultural': 'Agricultural and livestock industries',
        'Office': 'Office worker',
        'Public': 'Public officer',
        'Student': 'Student',
        'Housewife': 'Housewife',
        'Etc': 'Etc'
      },
      acq_relations: {
        'family': 'Family',
        'relative': 'Relatives',
        'friends': 'Friends',
        'business': 'Business',
        'etc': 'Etc'
      },
      acc_array: {
        'family': 'Family',
        'relative': 'Relative',
        'friends': 'Friends',
        'acquaintance': 'Acquaintance',
        'business': 'Business',
        'group_tour': 'Group Tour',
        'etc': 'Etc'
      },
      visited_arr: {
        'none': 'None',
        '1_2_times': '1~2 times',
        '3_4_times': '3~4 times',
        '5_and_more_times': 'More than 5 times'
      },
      income_arr: {
        '200': '~ 200$',
        '200-700': '200$ ~ 700$',
        '700-1300': '700$ ~ 1,300$',
        '1300-2100': '1,300$ ~ 2,100$',
        '2100': '2,100$ ~'
      }
    }),
    watch: {
    },
    methods: {
        setLanguage(lang){
          const languageNames = new Intl.DisplayNames(['en'], {
            type: 'language'
          });
          return languageNames.of(lang)
        },
        getCountry(lang) {
          let res = 'None'
          if(lang){
          this.$store.state.countries.forEach(item => {
            if(item.code.toLowerCase() == lang.toLowerCase()){
              res = item.country
            }
          });
          }
          return res;
        },
        getPhoneCountryCode(phone){
          let data = '';
          if(phone){
          const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
          data =  phoneUtil.getRegionCodeForNumber(phoneUtil.parse(phone))
          }
          return data;
        },
    },
    mounted(){
    }
  }
</script>
<style scoped>
.view-main-title {
  color: #4d6585 !important;
  font-weight: 500;
  font-size: 16px;
}
.view-title {
  color: #4d6585 !important;
  font-weight: 500;
}
.chip-status-1{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-2{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
</style>