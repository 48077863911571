<template>
  <div class="pa-5">
    <v-row>
      <v-col cols="12">
    <v-card class="card-style rounded-1" >
      <v-card-title>
        <h3>Search Criteria</h3>
      </v-card-title>
      <v-card-text>
        <v-divider></v-divider>
        <v-row class="pa-5">
        <v-col cols="12" md="6">
          <div class="px-0 pb-2 text-h6">Reference Number</div>
          <v-text-field
            color="#677788"
            height="45"
            outlined
            hide-details="auto"
            disabled
            v-if="formdata.applicationNumber || formdata.variable || formdata.email"
          ></v-text-field>
          <v-text-field
            v-model="formdata.referenceNumber"
            color="#677788"
            :rules="[formRules.required]"
            height="45"
            outlined
            hide-details="auto"
            v-else
          ></v-text-field>
          <div class="hint-text">
              Please, enter applicant's internal reference number.
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="px-0 pb-2 text-h6">Application Number</div>
          <v-text-field
            color="#677788"
            height="45"
            outlined
            hide-details="auto"
            disabled
            v-if="formdata.referenceNumber || formdata.variable || formdata.email"
          ></v-text-field>
          <v-text-field
            v-model="formdata.applicationNumber"
            color="#677788"
            :rules="[formRules.required]"
            height="45"
            outlined
            hide-details="auto"
            v-else
          ></v-text-field>
          <div class="hint-text">
              Please, enter applicant's application number if such has already been issued by the U.S. Customs and Border Protection Agency.
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="px-0 pb-2 text-h6">Multipurpose Variable</div>
          <v-text-field
            color="#677788"
            height="45"
            outlined
            hide-details="auto"
            disabled
            v-if="formdata.referenceNumber || formdata.applicationNumber || formdata.email"
          ></v-text-field>
          <v-text-field
            v-model="formdata.variable"
            color="#677788"
            :rules="[formRules.required]"
            height="45"
            outlined
            hide-details="auto"
            v-else
          ></v-text-field>
          <div class="hint-text">
              Please, enter multipurpose field value. This can be Passport Number, First or Last Name, City, or any field value that exists in the application.
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="px-0 pb-2 text-h6">Email Address</div>
          <v-text-field
            color="#677788"
            height="45"
            outlined
            hide-details="auto"
            disabled
            v-if="formdata.referenceNumber || formdata.applicationNumber || formdata.variable"
          ></v-text-field>
          <v-text-field
            v-model="formdata.email"
            color="#677788"
            :rules="[formRules.required]"
            height="45"
            outlined
            hide-details="auto"
            v-else
          ></v-text-field>
          <div class="hint-text">
              Please, enter applicant's email address.
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn color="#00A34E" class="white--text pa-5" @click="searchApplication()" v-if="formdata.referenceNumber || formdata.applicationNumber || formdata.variable || formdata.email"><v-icon class="mr-1">mdi-magnify</v-icon>Search</v-btn>
        <v-btn color="#00A34E" class="white--text pa-5" v-else disabled><v-icon class="mr-1">mdi-magnify</v-icon>Search</v-btn>
      </v-card-actions>
    </v-card>
      </v-col>
      <v-col cols="12" v-if="search_card">
        <v-card class="card-style rounded-1" >
    <v-row class="ma-2">
      <v-col cols="12" md="9" class="flex-items">
        <h2>Search Results</h2>
      </v-col>
      <v-col cols="12" md="3">
      <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          label="Search applications"
          single-line
          hide-details
          color="#38435a"
        ></v-text-field>
      </v-col>
      </v-row>
      <v-card class="mb-1 dt-container" elevation="0" outlined>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="data"
        item-key="id"
        :options.sync="options"
        hide-default-footer
      >
      <template v-slot:[`item.applicant`]="{ item }">
        <span v-if="item.data">{{(urlDecode(item.data.first_given_names || '')).toUpperCase()}} {{(urlDecode(item.data.family_names || '')).toUpperCase()}}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div v-if="$store.state.user.role == 'agent'">
        <v-chip
          class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
          v-if="item.submitted == 1"
          small
        >
        <span>Completed</span>
        </v-chip>
        <span v-else>
          <span v-if="item.assignedTo == null || item.assignedTo == ''">
            <v-chip
            class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
            v-if="item.express == 1"
            small
          >
          <span>Express</span>
          </v-chip>
          <v-chip
            class="chip-status-3 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
            small
          >
          <span>Available</span>
          </v-chip>
          </span>
          <span v-else>
            <v-chip
            class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
            v-if="item.express == 1"
            small
          > 
          <span>Express</span>
          </v-chip>
          <v-chip
            class="chip-status-4 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
            small
          >
          <span>Processing</span>
          </v-chip>
          </span>
        </span>
        </div>

        <div v-if="$store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'admin'">
        <v-chip
          class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
          v-if="item.submitted == 1"
          small
        >
        <span>Submitted</span>
        </v-chip>
        <span v-else>
          <span v-if="item.assignedTo == null || item.assignedTo == ''">
            <v-chip v-if="item.passportValidationData != '' && (item.type == 'USA-ESTA' || item.type == 'UK-EVW' || item.type == 'KH-EVISA' || item.type == 'VN-EVISA')"
                class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Passport Uploaded</span>
            </v-chip>
            <v-chip v-else-if="(item.type == 'USA-ESTA' || item.type == 'UK-EVW' || item.type == 'KH-EVISA' || item.type == 'VN-EVISA')"
                class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Pending Passport</span>
            </v-chip>
            <v-chip v-if="item.passportDataMatch == 0 && item.passportValidationData"
                class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Data Mismatch</span>
            </v-chip>
            <v-chip
              class="chip-status-3 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Available</span>
            </v-chip>
          </span>
          <span v-else>
            <span v-if="item.ticketCreated == 1">
              <v-chip v-if="item.passportValidationData != '' && (item.type == 'USA-ESTA' || item.type == 'UK-EVW' || item.type == 'KH-EVISA' || item.type == 'VN-EVISA')"
                class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Passport Uploaded</span>
              </v-chip>
              <v-chip v-else-if="(item.type == 'USA-ESTA' || item.type == 'UK-EVW' || item.type == 'KH-EVISA' || item.type == 'VN-EVISA')"
                  class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                  small
                >
                <span>Pending Passport</span>
              </v-chip>
              <v-chip v-if="item.issue != null"
                  class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                  small
                >
                <span>Issue</span>
              </v-chip>
              <v-chip
                class="chip-status-3 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Awaiting Response</span>
              </v-chip>
            </span>
            
            <span v-else>
              <v-chip v-if="item.passportValidationData != '' && (item.type == 'USA-ESTA' || item.type == 'UK-EVW' || item.type == 'KH-EVISA' || item.type == 'VN-EVISA')"
                  class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                  small
                >
                <span>Passport Uploaded</span>
              </v-chip>
              <v-chip v-else-if="(item.type == 'USA-ESTA' || item.type == 'UK-EVW' || item.type == 'KH-EVISA' || item.type == 'VN-EVISA')"
                  class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                  small
                >
                <span>Pending Passport</span>
              </v-chip>
              <v-chip v-if="item.issue != null"
                  class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                  small
                >
                <span>Issue</span>
              </v-chip>
              <v-chip
                class="chip-status-3 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Processing</span>
              </v-chip>
            </span>
          </span>
        </span>
        </div>
      </template>
      <template v-slot:[`item.assigned_to`]="{ item }">
        <span v-if="item.accounts">{{(urlDecode(item.accounts.firstName || '')).toUpperCase()}} {{(urlDecode(item.accounts.lastName || '')).toUpperCase()}}</span>
      </template>
          <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  outlined
                  color="#166ecf"
                  :to="'/' + $store.state.user.role + '/search/view/'+ item.token + '/'"
                >
                <v-icon class="mr-1">mdi-eye</v-icon>
                View
                </v-btn>
          </template>
      </v-data-table>
      </v-card>
      <v-row class="ma-2" align="center" justify="center">
      <v-col cols="auto" class="flex-items">
          <span class="px-2">Row Per Page: </span>
          <span>
            <v-select
              dense
              outlined
              hide-details
              :value="options.itemsPerPage"
              style="max-width: 90px"
              class="rounded-lg"
              @change="options.itemsPerPage = parseInt($event, 10)"
              :items="perPageChoices"
            >
            </v-select>
          </span>
      </v-col>
      <v-col cols="auto" class="mx-auto text-truncate" no-gutters>
      Showing {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + 1 : 0 }} to {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + data.length : 0 }} of {{ paginationData.count }} entries
      </v-col>
      <v-col cols="auto">
          <v-pagination
              v-model="options.page"
              class="rounded-lg"
              :total-visible="7"
              color="#38435a"
              :length="paginationData.lastPage">
          </v-pagination>
      </v-col>
        </v-row>
      </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    components: {
    },
    data: () => ({
      search: '',
      headers: [
          { text: 'REF#', value: 'token' },
          { text: 'TYPE#', value: 'type'},
          { text: 'APPLICANT', value: 'applicant'},
          { text: 'STATUS', value: 'status', width: 300},
          { text: 'ASSIGNED', value: 'assigned_to'},
          { text: 'NOTE', value: 'note', width: 200 },
          { text: 'ACTIONS', value: 'actions' },
        ],
      data: [],
      perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
      options: {},
      paginationData: {},
      loading: false,
      formdata: {
        referenceNumber: null,
        applicationNumber: null,
        variable: null,
        email: null,
      },
      text: '',
      field: '',
      search_card: false,
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      search: {
        handler () {
          setTimeout(() => {
            this.options.page = 1;
            this.initialize();
          }, 600);
        },
      },
    },
    methods: {
        initialize (){
        if(this.field && this.text){
        this.loading = true;
        let page = this.options.page;
        let itemPerPage = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
        let order = this.options.sortBy.length > 0 ? this.options.sortDesc[0] ? 'desc' : 'asc' : '';
        let search = this.search;
        this.axiosCall('/applications/search/' + this.field + '?page=' + page + '&itemPerPage=' + itemPerPage + '&sortBy=' + sortBy + '&order=' + order + '&text=' + this.text + '&searchKey=' + search, 'GET').then((res) => {
              this.loading = false
              res.data.data.forEach(item => {
                item.data = this.isJsonString(item.data) ? JSON.parse(this.sanitizeJsonString(item.data)) : {}
                if(item.accounts == null){
                  item.accounts = {}
                }
              });
              this.data = res.data.data
              console.log(this.data)
              this.paginationData = res.data.pagination;
          })
        }
        else{
          this.data = [];
        }
      },
      isJsonString(str) {
          try {
              JSON.parse(str);
          } catch (e) {
              return false;
          }
          return true;
      },
      searchApplication(){
        if(this.formdata.referenceNumber){
          this.text = this.formdata.referenceNumber
          this.field = 'reference'
        }
        else if(this.formdata.applicationNumber){
          this.text = this.formdata.applicationNumber
          this.field = 'application'
        }
        else if(this.formdata.variable){
          this.text = this.formdata.variable
          this.field = 'variable'
        }
        else if(this.formdata.email){
          this.text = this.formdata.email
          this.field = 'email'
        }
        // this.options = {}
        this.search_card = true;
        this.initialize();
       },
    },
    mounted(){
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #2f8547 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.chip-status-3{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-4{
  background-color: #fcedb9 !important;
  color: #b02c00 !important;
}
.chip-status-5{
  background-color: #ffe7f2 !important;
  color: #c4093c !important;
}
.chip-status-6{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
</style>