import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    countries: [
      {
        code: "AF",
        country: "Afghanistan"
      },
      {
        code: "AX",
        country: "Aland Islands"
      },
      {
        code: "AL",
        country: "Albania"
      },
      {
        code: "DZ",
        country: "Algeria"
      },
      {
        code: "AS",
        country: "American Samoa"
      },
      {
        code: "AD",
        country: "Andorra"
      },
      {
        code: "AO",
        country: "Angola"
      },
      {
        code: "AI",
        country: "Anguilla"
      },
      {
        code: "AQ",
        country: "Antarctica"
      },
      {
        code: "AG",
        country: "Antigua and Barbuda"
      },
      {
        code: "AR",
        country: "Argentina"
      },
      {
        code: "AM",
        country: "Armenia"
      },
      {
        code: "AW",
        country: "Aruba"
      },
      {
        code: "AU",
        country: "Australia"
      },
      {
        code: "AT",
        country: "Austria"
      },
      {
        code: "AZ",
        country: "Azerbaijan"
      },
      {
        code: "BS",
        country: "Bahamas"
      },
      {
        code: "BH",
        country: "Bahrain"
      },
      {
        code: "BD",
        country: "Bangladesh"
      },
      {
        code: "BB",
        country: "Barbados"
      },
      {
        code: "BY",
        country: "Belarus"
      },
      {
        code: "BE",
        country: "Belgium"
      },
      {
        code: "BZ",
        country: "Belize"
      },
      {
        code: "BJ",
        country: "Benin"
      },
      {
        code: "BM",
        country: "Bermuda"
      },
      {
        code: "BT",
        country: "Bhutan"
      },
      {
        code: "BO",
        country: "Bolivia"
      },
      {
        code: "BQ",
        country: "Bonaire"
      },
      {
        code: "BA",
        country: "Bosnia and Herzegovina"
      },
      {
        code: "BW",
        country: "Botswana"
      },
      {
        code: "BV",
        country: "Bouvet Island"
      },
      {
        code: "BR",
        country: "Brazil"
      },
      {
        code: "IO",
        country: "British Indian Ocean Territory"
      },
      {
        code: "BN",
        country: "Brunei"
      },
      {
        code: "BG",
        country: "Bulgaria"
      },
      {
        code: "BF",
        country: "Burkina Faso"
      },
      {
        code: "BU",
        country: "Burma"
      },
      {
        code: "BI",
        country: "Burundi"
      },
      {
        code: "KH",
        country: "Cambodia"
      },
      {
        code: "CM",
        country: "Cameroon"
      },
      {
        code: "CA",
        country: "Canada"
      },
      {
        code: "CT",
        country: "Canton and Enderbury Islands"
      },
      {
        code: "CV",
        country: "Cape Verde"
      },
      {
        code: "KY",
        country: "Cayman Islands"
      },
      {
        code: "CF",
        country: "Central African Republic"
      },
      {
        code: "TD",
        country: "Chad"
      },
      {
        code: "CL",
        country: "Chile"
      },
      {
        code: "CN",
        country: "China"
      },
      {
        code: "CX",
        country: "Christmas Island"
      },
      {
        code: "CC",
        country: "Cocos — KEELING Islands"
      },
      {
        code: "CO",
        country: "Colombia"
      },
      {
        code: "KM",
        country: "Comoros"
      },
      {
        code: "CD",
        country: "Congo"
      },
      {
        code: "CG",
        country: "Congo"
      },
      {
        code: "CK",
        country: "Cook Islands"
      },
      {
        code: "CR",
        country: "Costa Rica"
      },
      {
        code: "CI",
        country: "Cote D'ivoire"
      },
      {
        code: "HR",
        country: "Croatia"
      },
      {
        code: "CU",
        country: "Cuba"
      },
      {
        code: "CW",
        country: "Curacao"
      },
      {
        code: "CY",
        country: "Cyprus"
      },
      {
        code: "CZ",
        country: "Czech Republic"
      },
      {
        code: "YD",
        country: "Democratic Yemen"
      },
      {
        code: "DK",
        country: "Denmark"
      },
      {
        code: "DJ",
        country: "Djibouti"
      },
      {
        code: "DM",
        country: "Dominica"
      },
      {
        code: "DO",
        country: "Dominican Republic"
      },
      {
        code: "NQ",
        country: "Dronning Maud Land"
      },
      {
        code: "TP",
        country: "East Timor"
      },
      {
        code: "EC",
        country: "Ecuador"
      },
      {
        code: "EG",
        country: "Egypt"
      },
      {
        code: "SV",
        country: "El Salvador"
      },
      {
        code: "GQ",
        country: "Equatorial Guinea"
      },
      {
        code: "ER",
        country: "Eritrea"
      },
      {
        code: "EE",
        country: "Estonia"
      },
      {
        code: "ET",
        country: "Ethiopia"
      },
      {
        code: "FK",
        country: "Falkland Islands"
      },
      {
        code: "FO",
        country: "Faroe Islands"
      },
      {
        code: "FJ",
        country: "Fiji"
      },
      {
        code: "FI",
        country: "Finland"
      },
      {
        code: "FR",
        country: "France"
      },
      {
        code: "FX",
        country: "France Metropolitan"
      },
      {
        code: "GF",
        country: "French Guiana"
      },
      {
        code: "PF",
        country: "French Polynesia"
      },
      {
        code: "TF",
        country: "French Southern Territories"
      },
      {
        code: "GA",
        country: "Gabon"
      },
      {
        code: "GM",
        country: "Gambia"
      },
      {
        code: "GE",
        country: "Georgia"
      },
      {
        code: "DE",
        country: "Germany"
      },
      {
        code: "GH",
        country: "Ghana"
      },
      {
        code: "GI",
        country: "Gibraltar"
      },
      {
        code: "GR",
        country: "Greece"
      },
      {
        code: "GL",
        country: "Greenland"
      },
      {
        code: "GD",
        country: "Grenada"
      },
      {
        code: "GP",
        country: "Guadeloupe"
      },
      {
        code: "GU",
        country: "Guam"
      },
      {
        code: "GT",
        country: "Guatemala"
      },
      {
        code: "GG",
        country: "Guernsey"
      },
      {
        code: "GN",
        country: "Guinea"
      },
      {
        code: "GW",
        country: "Guinea-bissau"
      },
      {
        code: "GY",
        country: "Guyana"
      },
      {
        code: "HT",
        country: "Haiti"
      },
      {
        code: "HM",
        country: "Heard and Mc Donald Islands"
      },
      {
        code: "HN",
        country: "Honduras"
      },
      {
        code: "HK",
        country: "Hong Kong"
      },
      {
        code: "HU",
        country: "Hungary"
      },
      {
        code: "IS",
        country: "Iceland"
      },
      {
        code: "IN",
        country: "India"
      },
      {
        code: "ID",
        country: "Indonesia"
      },
      {
        code: "IR",
        country: "Iran"
      },
      {
        code: "IQ",
        country: "Iraq"
      },
      {
        code: "IE",
        country: "Ireland"
      },
      {
        code: "IM",
        country: "Isle Of Man"
      },
      {
        code: "IL",
        country: "Israel"
      },
      {
        code: "IT",
        country: "Italy"
      },
      {
        code: "JM",
        country: "Jamaica"
      },
      {
        code: "JP",
        country: "Japan"
      },
      {
        code: "JE",
        country: "Jersey"
      },
      {
        code: "JT",
        country: "Johnston Island"
      },
      {
        code: "JO",
        country: "Jordan"
      },
      {
        code: "KZ",
        country: "Kazakhstan"
      },
      {
        code: "KE",
        country: "Kenya"
      },
      {
        code: "KI",
        country: "Kiribati"
      },
      {
        code: "KV",
        country: "Kosovo"
      },
      {
        code: "KW",
        country: "Kuwait"
      },
      {
        code: "KG",
        country: "Kyrgyzstan"
      },
      {
        code: "LA",
        country: "Laos"
      },
      {
        code: "LV",
        country: "Latvia"
      },
      {
        code: "LB",
        country: "Lebanon"
      },
      {
        code: "LS",
        country: "Lesotho"
      },
      {
        code: "LR",
        country: "Liberia"
      },
      {
        code: "LY",
        country: "Libya"
      },
      {
        code: "LI",
        country: "Liechtenstein"
      },
      {
        code: "LT",
        country: "Lithuania"
      },
      {
        code: "LU",
        country: "Luxembourg"
      },
      {
        code: "MO",
        country: "Macau"
      },
      {
        code: "MK",
        country: "Macedonia"
      },
      {
        code: "MG",
        country: "Madagascar"
      },
      {
        code: "MW",
        country: "Malawi"
      },
      {
        code: "MY",
        country: "Malaysia"
      },
      {
        code: "MV",
        country: "Maldives"
      },
      {
        code: "ML",
        country: "Mali"
      },
      {
        code: "MT",
        country: "Malta"
      },
      {
        code: "MH",
        country: "Marshall Islands"
      },
      {
        code: "MQ",
        country: "Martinique"
      },
      {
        code: "MR",
        country: "Mauritania"
      },
      {
        code: "MU",
        country: "Mauritius"
      },
      {
        code: "YT",
        country: "Mayotte"
      },
      {
        code: "MX",
        country: "Mexico"
      },
      {
        code: "FM",
        country: "Micronesia - Federated States Of"
      },
      {
        code: "MI",
        country: "Midway Islands"
      },
      {
        code: "MD",
        country: "Moldova"
      },
      {
        code: "MC",
        country: "Monaco"
      },
      {
        code: "MN",
        country: "Mongolia"
      },
      {
        code: "ME",
        country: "Montenegro"
      },
      {
        code: "MS",
        country: "Montserrat"
      },
      {
        code: "MA",
        country: "Morocco"
      },
      {
        code: "MZ",
        country: "Mozambique"
      },
      {
        code: "MM",
        country: "Myanmar"
      },
      {
        code: "NA",
        country: "Namibia"
      },
      {
        code: "NR",
        country: "Nauru"
      },
      {
        code: "NP",
        country: "Nepal"
      },
      {
        code: "NL",
        country: "Netherlands"
      },
      {
        code: "AN",
        country: "Netherlands Antilles"
      },
      {
        code: "NT",
        country: "Neutral Zone"
      },
      {
        code: "NC",
        country: "New Caledonia"
      },
      {
        code: "NZ",
        country: "New Zealand"
      },
      {
        code: "NI",
        country: "Nicaragua"
      },
      {
        code: "NE",
        country: "Niger"
      },
      {
        code: "NG",
        country: "Nigeria"
      },
      {
        code: "NU",
        country: "Niue"
      },
      {
        code: "NF",
        country: "Norfolk Island"
      },
      {
        code: "KP",
        country: "North Korea"
      },
      {
        code: "MP",
        country: "Northern Mariana Islands"
      },
      {
        code: "NO",
        country: "Norway"
      },
      {
        code: "OM",
        country: "Oman"
      },
      {
        code: "PK",
        country: "Pakistan"
      },
      {
        code: "PW",
        country: "Palau"
      },
      {
        code: "PS",
        country: "Palestinian Territories"
      },
      {
        code: "PA",
        country: "Panama"
      },
      {
        code: "PG",
        country: "Papua New Guinea"
      },
      {
        code: "PY",
        country: "Paraguay"
      },
      {
        code: "PE",
        country: "Peru"
      },
      {
        code: "PH",
        country: "Philippines"
      },
      {
        code: "PN",
        country: "Pitcairn Islands"
      },
      {
        code: "PL",
        country: "Poland"
      },
      {
        code: "PT",
        country: "Portugal"
      },
      {
        code: "PR",
        country: "Puerto Rico"
      },
      {
        code: "QA",
        country: "Qatar"
      },
      {
        code: "RE",
        country: "Reunion"
      },
      {
        code: "RO",
        country: "Romania"
      },
      {
        code: "RU",
        country: "Russia"
      },
      {
        code: "RW",
        country: "Rwanda"
      },
      {
        code: "BL",
        country: "Saint Barthelemy"
      },
      {
        code: "KN",
        country: "Saint Kitts and Nevis"
      },
      {
        code: "LC",
        country: "Saint Lucia"
      },
      {
        code: "MF",
        country: "Saint Martin — French Part"
      },
      {
        code: "VC",
        country: "Saint Vincent and The Grenadines"
      },
      {
        code: "WS",
        country: "Samoa"
      },
      {
        code: "SM",
        country: "San Marino"
      },
      {
        code: "ST",
        country: "Sao Tome and Principe"
      },
      {
        code: "SA",
        country: "Saudi Arabia"
      },
      {
        code: "SN",
        country: "Senegal"
      },
      {
        code: "RS",
        country: "Serbia"
      },
      {
        code: "CS",
        country: "Serbia and Montenegro"
      },
      {
        code: "SC",
        country: "Seychelles"
      },
      {
        code: "SL",
        country: "Sierra Leone"
      },
      {
        code: "SG",
        country: "Singapore"
      },
      {
        code: "SX",
        country: "Saint Martin — Dutch Part"
      },
      {
        code: "SK",
        country: "Slovakia"
      },
      {
        code: "SI",
        country: "Slovenia"
      },
      {
        code: "SB",
        country: "Solomon Islands"
      },
      {
        code: "SO",
        country: "Somalia"
      },
      {
        code: "ZA",
        country: "South Africa"
      },
      {
        code: "GS",
        country: "South Georgia and The South Sandwich Islands"
      },
      {
        code: "KR",
        country: "South Korea"
      },
      {
        code: "SS",
        country: "South Sudan"
      },
      {
        code: "ES",
        country: "Spain"
      },
      {
        code: "LK",
        country: "Sri Lanka"
      },
      {
        code: "SH",
        country: "St. Helena"
      },
      {
        code: "PM",
        country: "St. Pierre and Miquelon"
      },
      {
        code: "SD",
        country: "Sudan"
      },
      {
        code: "SR",
        country: "Suriname"
      },
      {
        code: "SJ",
        country: "Svalbard and Jan Mayen Islands"
      },
      {
        code: "SZ",
        country: "Swaziland"
      },
      {
        code: "SE",
        country: "Sweden"
      },
      {
        code: "CH",
        country: "Switzerland"
      },
      {
        code: "SY",
        country: "Syria"
      },
      {
        code: "TW",
        country: "Taiwan"
      },
      {
        code: "TJ",
        country: "Tajikistan"
      },
      {
        code: "TZ",
        country: "Tanzania"
      },
      {
        code: "TH",
        country: "Thailand"
      },
      {
        code: "TL",
        country: "Timor-leste"
      },
      {
        code: "TG",
        country: "Togo"
      },
      {
        code: "TK",
        country: "Tokelau"
      },
      {
        code: "TO",
        country: "Tonga"
      },
      {
        code: "TT",
        country: "Trinidad and Tobago"
      },
      {
        code: "TN",
        country: "Tunisia"
      },
      {
        code: "TR",
        country: "Turkey"
      },
      {
        code: "TM",
        country: "Turkmenistan"
      },
      {
        code: "TC",
        country: "Turks and Caicos Islands"
      },
      {
        code: "TV",
        country: "Tuvalu"
      },
      {
        code: "UG",
        country: "Uganda"
      },
      {
        code: "UA",
        country: "Ukraine"
      },
      {
        code: "AE",
        country: "United Arab Emirates"
      },
      {
        code: "GB",
        country: "United Kingdom"
      },
      {
        code: "US",
        country: "United States"
      },
      {
        code: "UM",
        country: "United States Minor Outlying Islands"
      },
      {
        code: "UY",
        country: "Uruguay"
      },
      {
        code: "UZ",
        country: "Uzbekistan"
      },
      {
        code: "VU",
        country: "Vanuatu"
      },
      {
        code: "VA",
        country: "Vatican City State — HOLY See"
      },
      {
        code: "VE",
        country: "Venezuela"
      },
      {
        code: "VN",
        country: "Vietnam"
      },
      {
        code: "VG",
        country: "Virgin Islands — British"
      },
      {
        code: "VI",
        country: "Virgin Islands — U.S."
      },
      {
        code: "WK",
        country: "Wake Island"
      },
      {
        code: "WF",
        country: "Wallis and Futuna Islands"
      },
      {
        code: "EH",
        country: "Western Sahara"
      },
      {
        code: "YE",
        country: "Yemen"
      },
      {
        code: "ZR",
        country: "Zaire"
      },
      {
        code: "ZM",
        country: "Zambia"
      },
      {
        code: "ZW",
        country: "Zimbabwe"
      }
    ],
    application_issues: [
      {id: 'OK_NO_ISSUES', desc: 'OK! No issues.'},
      {id: 'EXPECTING_PASSPORT_UPLOAD', desc: 'Expecting Passport Upload.'},
      {id: 'APPLICATION_EXPIRED', desc: 'Application Expired Status upon decision checking.'},
      {id: 'PREVIOUS_APPLICATION_TRAVEL_NOT_AUTHORIZED', desc: 'PREVIOUSLY SUBMITTED APPLICATION. TRAVEL NOT AUTHORIZED'},
      {id: 'ANOTHER_APPLICATION_ALREADY_EXISTS', desc: 'Another application with this passport information already exists.'},
      {id: 'ANOTHER_APPLICATION_ALREADY_EXISTS_PENDING_APPROVAL', desc: 'Another application with this passport information already exists. Previous application is pending approval.'},
      {id: 'ANOTHER_APPLICATION_ALREADY_EXISTS_CANNOT_RETRIEVE', desc: 'Another application with this passport information already exists. Cannot retrieve previous application.'},
      {id: 'INVALID_PASSPORT_NUMBER', desc: 'Passport Number is invalid.'},
      {id: 'PASSPORT_INELIGIBLE_FOR_VISA_WAIVER_PROGRAM', desc: 'Passport ineligible for Visa Waiver Program travel.'},
      {id: 'DATE_OF_BIRTH_GREATER_THAN_CURRENT_DATE', desc: 'Date of Birth Greater than the Current Date.'},
      {id: 'UNABLE_TO_PROCESS_PASSPORT_IMAGE', desc: 'Passport Image Unacceptable.'},
      {id: 'PAYMENT_STATUS_COULD_NOT_BE_DETERMINED', desc: 'THE PAYMENT STATUS COULD NOT BE DETERMINED AT THIS TIME. PLEASE CHECK BACK LATER FOR THE PAYMENT STATUS.'},
      {id: 'FORBIDDEN_COUNTRY_OF_BIRTH_OTHER', desc: 'Country of Birth on Forbidden List (IRAN). Specify ALL Statements that are related to Country of Birth.'},
      {id: 'EVW_PASSPORT_REUPLOAD_REQUIRED', desc: '[UK-EVW] - New Passport Image Needed: The passport image you submitted is not readable.'},
      {id: 'OTHER', desc: 'Other issue.'}
    ],
    application_issues_instructions: {
        'OK_NO_ISSUES': 'OK! No issues.',
        'EXPECTING_PASSPORT_UPLOAD': 'Expecting Passport Upload.',
        'PREVIOUS_APPLICATION_TRAVEL_NOT_AUTHORIZED': 'PREVIOUSLY SUBMITTED APPLICATION. TRAVEL NOT AUTHORIZED',
        'APPLICATION_EXPIRED': 'ATTENTION: You need to request real passport copy from applicant in order to submit this application.',
        'ANOTHER_APPLICATION_ALREADY_EXISTS': 'Another application with this passport information already exists.',
        'ANOTHER_APPLICATION_ALREADY_EXISTS_PENDING_APPROVAL': 'Unable to Process Application: Another application with this passport information already exists.<br><strong>WHAT YOU NEED TO DO?</strong><br>Upon retrieving, the status is Authorization Pending? You need to retrieve it until status is "Approved" and then re-submit entire application by canceling the current (old) one.',
        'ANOTHER_APPLICATION_ALREADY_EXISTS_CANNOT_RETRIEVE': 'Another application with this passport information already exists. Cannot retrieve previous application.',
        'INVALID_PASSPORT_NUMBER': 'Passport Number is invalid.',
        'PASSPORT_INELIGIBLE_FOR_VISA_WAIVER_PROGRAM': 'Passport ineligible for Visa Waiver Program travel.',
        'DATE_OF_BIRTH_GREATER_THAN_CURRENT_DATE': 'Date of Birth Greater than the Current Date.',
        'UNABLE_TO_PROCESS_PASSPORT_IMAGE': 'Passport Image Unacceptable.',
        'FORBIDDEN_COUNTRY_OF_BIRTH_OTHER': 'Country of Birth on Forbidden List (IRAN). Specify ALL Statements that are related to Country of Birth.',
        'PAYMENT_STATUS_COULD_NOT_BE_DETERMINED': 'THE PAYMENT STATUS COULD NOT BE DETERMINED AT THIS TIME. PLEASE CHECK BACK LATER FOR THE PAYMENT STATUS.<br><strong>WHAT YOU NEED TO DO?</strong><br>Keep retrieving the application status every 30-45 minutes until system allows you to make payment again.',
        'EVW_PASSPORT_REUPLOAD_REQUIRED': '[UK-EVW] - The passport image you submitted is not readable. Please, request a new passport image from the applicant or follow-up on an already existing open ticket.',
        'OTHER': 'Other issue.'
    },
    response_types: [
      {id: 'ANOTHER_APPLICATION_ALREADY_EXISTS', desc: 'Another application already exists'},
      {id: 'REFUND', desc: 'Asking for Refund (Money back)'},
      {id: 'REFUND_NO_PASSPORT', desc: 'Asking for Refund (Money back) - No Passport Uploaded'},
      {id: 'VISA_WAIVER', desc: 'Passport ineligible for Visa Waiver Program travel'},
      {id: 'APPLICATION_STATUS', desc: 'What is my Application Status?'},
      {id: 'THANKS_FOR_PROVIDED_INFO', desc: 'Thanks for provided information'},
      {id: 'PASSPORT_ISSUE_OVER_11_YEARS', desc: 'Passport Issue Problem Over 11 Years'},
      {id: 'PASSPORT_COPY_REQUIRED', desc: 'Passport Copy Required (Application Expired Issue)'},
      {id: 'PRINT_ESTA', desc: 'Do I need to Print Application?'},
      {id: 'WHO_WE_ARE', desc: 'Who we are?'},
      {id: 'HOW_MUCH_WE_CHARGE', desc: 'How much we charge?'},
      {id: 'UPDATE_TRAVEL_INFO', desc: 'How to update Travel Information?'},
      {id: 'INVALID_PASSPORT_NUMBER', desc: 'Invalid Passport Number'},
      {id: 'PAYMENT_RECEIPT', desc: 'Payment Receipt?'},
      {id: 'COUNTRY_OF_BIRTH_OTHER', desc: 'Country of Birth on Forbidden List (IRAN). Specify ALL Statements that are related to Country of Birth.'},
      {id: 'CHECK_APPLICATION_STATUS', desc: 'How do I check/verify Application on the Official Website?'},
      {id: 'CANCEL_DELETE_DATA_APPLICATION', desc: 'Cancel/Delete my Application (Data)'},
      {id: 'CHARGEBACK_WARNING', desc: 'Chargeback Warning'},
      {id: 'CHARGEBACK_THREAT', desc: 'Chargeback Threat!'},
    ],
  },
  mutations: {
    setUserData(state, data){
      state.user = data
    }
  },
  actions: {
    setUser(context, data){
      // let _this = context.state.thisVue;
      context.commit('setUserData', data);
      
    }
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})